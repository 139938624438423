import { login, getInfo, logout } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'

const getDefaultState = () => {
    return {
      token: getToken(),
      userInfo: {},
      userName: ''
    }
}

const state = getDefaultState()

const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USER_INFO: (state, data) => {
      state.userInfo = data
    },
    SET_USER_NAME: (state, userName) => {
        state.userName = userName
    },
}

const actions = {
    // user login
    login({ commit, dispatch }, userInfo) {
        return new Promise((resolve, reject) => {
            login({ ...userInfo }).then(async response => {
                const { token } = response
                commit('SET_TOKEN', token)
                setToken(token)
                await dispatch('getInfo')
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // get user info
    getInfo({ commit, dispatch }) {
        return new Promise((resolve, reject) => {
            getInfo().then(async response => {

                if (!response) {
                    return reject('验证失败，请重新登录。')
                }

                commit('SET_USER_INFO', { ...response })
                commit('SET_USER_NAME', response.username)
                resolve({ ...response })
            }).catch(error => {
                reject(error)
            })
        })
    },
    // user logout
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            logout().then(() => {
                removeToken() // must remove  token  first
                commit('RESET_STATE')
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            removeToken() // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}