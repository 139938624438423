import axios from 'axios'
// 自定义消息弹窗只出现一次
import { message } from '@/utils/tips'
import { getToken } from '@/utils/auth'
import { MessageBox } from 'element-ui'

// export const BASEURL = 'http://civilization-api.ex-info.com/admin/v1/civilization'
export const BASEURL = '/'
// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: BASEURL,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 10000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['Authorization'] = getToken()
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if (res && res.code !== 0) {
      message.error(res.message)
      // to re-login
      // if(){
      //   MessageBox.alert('请重新登录系统', '退出', {
      //     confirmButtonText: '退出',
      //     type: 'warning',
      //     showClose: false,
      //     callback: action => {
      //       store.dispatch('user/resetToken').then(() => {
      //         location.reload()
      //         // router.replace(`/login`)  //很多提示返回,所以直接刷新退出
      //       })
      //     }
      //   })
      // }
      return Promise.reject(res)
    } else {
      return Promise.resolve(res.data)
    }
  },
  error => {
    // console.log('err' + error.response.data) // for debug
    const errorRes = error && error.response && error.response.data
    message.error(errorRes?.message || '服务器开小差了')
    return Promise.reject(error)
  }
)

export default service
