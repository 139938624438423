import request from '@/utils/request'


export function login(data) {
    return request({
        url: '/open/v1/admin/login',
        method: 'post',
        data
    })
}

export function getInfo(params) {
    return request({
        url: '/api/v1/common/manager/profile',
        method: 'get',
        params
    })
}

export function logout() {
    return request({
        url: '/api/v1/admin/logout',
        method: 'get',
    })
}
