import { MessageBox, Message } from 'element-ui'

// 自定义提示弹出
// export function customConfirm(title = '温馨提示', content, cb, error) {
//   MessageBox.alert(`<p><img style="margin-top:16px" src="${content.url || require('@/assets/images/tip1.png')}"/><div style="color: #AAB2C0;margin:6px 0 5px 0">${content.message}</div></p>`, title, {
//     dangerouslyUseHTMLString: true,
//     showCancelButton: true,
//     confirmButtonText: '确定',
//     cancelButtonText: '取消',
//     callback: action => {
//       if (action === 'confirm') {
//         cb && cb()
//       }
//       if (action === 'cancel') {
//         error && error()
//       }
//     }
//   })
// }

/**
 * 自定义消息弹窗只出现一次
 * 重置message，防止重复点击重复弹出message弹框
 * 用法:
 * import { message } from '@/utils/tips'
 * message.error("操作失败")
 * main.js全局挂载也可以某个页面使用：Vue.prototype.$messageSingle = message.success;
 *    ———— this.$messageSingle.info("操作失败"); 或者 this.$messageSingle.info({message:'操作失败'})
 *    ———— this.$messageSingle.infoTime("操作失败", 1000);
 * */
const showMessage = Symbol('showMessage')
class DoneMessage {
  [showMessage](type, options, single) {
    if (single) {
      if (document.getElementsByClassName('el-message').length === 0) {
        Message[type](options)
      }
    } else {
      Message[type](options)
    }
  }
  info(options, single = true) {
    this[showMessage]('info', options, single)
  }
  infoTime(options, time, single = true) {
    this[showMessage]('info', { showClose: true, message: options, duration: time == undefined ? 2000 : time }, single)
  }
  warning(options, single = true) {
    this[showMessage]('warning', options, single)
  }
  warningTime(options, time, single = true) {
    this[showMessage]('warning', { showClose: true, message: options, duration: time == undefined ? 2000 : time }, single)
  }
  error(options, single = true) {
    this[showMessage]('error', options, single)
  }
  // 可动态设置弹窗关闭时间
  errorTime(options, time, single = true) {
    this[showMessage]('error', { showClose: true, message: options, duration: time == undefined ? 2000 : time }, single)
  }
  success(options, single = true) {
    this[showMessage]('success', options, single)
  }
  successTime(options, time, single = true) {
    this[showMessage]('success', { showClose: true, message: options, duration: time == undefined ? 2000 : time }, single)
  }
}

export const message = new DoneMessage()
